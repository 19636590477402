<template>
  <v-container class="container-page">
    <v-card class="mt-0 transparent">
      <v-card-text class="px-0 py-0">
        <h2 class="font-weight-bold display-1">
          Comunicações
        </h2>
        <v-tabs
          slider-color="accent"
          class="tabs-transparent">
          <v-tab
            ripple
            :to="{name: 'Central' }">
            Todos
          </v-tab>
          <v-tab
            ripple
            :to="{name: 'CentralEmails' }">
            E-mails
          </v-tab>
          <v-tab
            ripple
            :to="{name: 'CentraLetters' }">
            Cartas
          </v-tab>
        </v-tabs>
        <v-card
          flat
          class="transparent content-tab">
          <transition
            name="slide"
            mode="out-in"
            appear>
            <router-view />
          </transition>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
  }
</script>

<style lang="sass" scoped>
  .content-tab
    height: calc(100vh - 160px)!important // because loading
</style>
