<template>
  <side-content
    modal
    :is-subcontext="$route.meta.subcontext || false"
    :title="$route.meta.title"
    @close="sideContentClose">
    <loading
      v-if="loading > 0"
      class="absolute-center" />

    <v-container v-else grid-list-lg class="pr-0">
      <h2 class="subtitle-1 textColor--text my-4">
        Destinatário
      </h2>
      <v-list dense>
        <list-item label="Nome" :value="invite.params.recipient.name" divider />
        <list-item label="Endereço" :value="invite.params.recipient.address.address" divider />
        <list-item label="Número" :value="invite.params.recipient.address.number" divider />
        <list-item label="Complemento" :value="invite.params.recipient.address.complement" divider />
        <list-item label="Bairro" :value="invite.params.recipient.address.neighborhood" divider />
        <list-item label="Cidade/Estado" :value="`${invite.params.recipient.address.city}-${invite.params.recipient.address.uf}`" divider />
        <list-item label="CEP" :value="invite.params.recipient.address.zipCode" divider />
      </v-list>
      <h2 class="subtitle-1 textColor--text my-4">
        Timeline
      </h2>
      <v-list flat dense>
        <template v-for="([date, msgs], index) in invite.timeline">
          <v-list-item :key="index">
            <v-list-item-content>
              <v-list-item-title class="body-1 font-weight-bold text-center black--text pt-2 pb-1" v-text="date" />
            </v-list-item-content>
          </v-list-item>
          <template v-for="(item, i) in msgs">
            <v-list-item :key="i">
              <v-list-item-icon>
                <v-icon color="primary" v-text="$root.icons.timeline.others" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold" v-text="item.time" />
                <v-list-item-subtitle>{{ item.state }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="i < (msgs.length - 1)" :key="'divider-'+i" />
          </template>
        </template>
      </v-list>
    </v-container>
  </side-content>
</template>

<script>
  import Loading from '@/components/ui/Loading'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ShowCentral',
    components: { Loading },
    computed: {
      ...mapGetters({
        invite: 'invites/record',
        loading: 'invites/recordLoading'
      })
    },
    watch: {
      '$route.params.inviteId' () {
        this.getRecord()
      }
    },
    beforeMount () {
      console.log(this.$route)
      this.getRecord()
    },
    methods: {
      sideContentClose () {
        this.$router.push({ name: this.$route.meta.routeClose })
      },
      getRecord () {
        this.$store.dispatch('invites/get', this.$route.params.inviteId)
      }
    }
  }
</script>
