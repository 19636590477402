<template>
  <v-container
    fluid
    grid-list-lg
    class="mt-0 px-0">
    <v-layout
      row
      wrap>
      <v-flex
        xs12
        class="px-0">
        <v-list class="py-0" dense>
          <v-list-item class="py-1 px-5">
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">
                Token
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">
                Tipo de comunicação
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">
                Status
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title class="body-2 font-weight-bold">
                Criação
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <perfect-scrollbar :options="options" @ps-y-reach-end="nextPage">
            <template v-for="(item, index) in invites">
              <v-list-item :key="index" class="py-1 px-5" link :to="{ name: 'CentralShow', params: { inviteId: item.id } }">
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ item.token }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ $t('invite.channel.' + item.channel) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ $t('invite.state.' + item.state) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title class="body-2">
                    {{ $d(item.updatedAt.toDate(), 'long') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider v-if="index + 1 < invites.length" :key="'divider-' + index" />
            </template>
          </perfect-scrollbar>
        </v-list>
      </v-flex>
    </v-layout>
    <transition
      name="slide"
      mode="out-in"
      appear>
      <router-view />
    </transition>
  </v-container>
</template>

<script>
  import WidgetChannels from '@/components/ui/widgets/channels/WidgetChannels'
  import WidgetChannelAccess from '@/components/ui/widgets/channels/WidgetChannelAccess'
  import { mapGetters } from 'vuex'

  const rowHeight = 48
  const compensationArea = 350
  const pageSize = () => parseInt((window.innerHeight - compensationArea) / rowHeight)

  export default {
    components: {
      WidgetChannels,
      WidgetChannelAccess
    },
    data () {
      return {
        options: { suppressScrollX: true, wheelPropagation: false },
        messageModal: '',
        showModal: false,
        stateList: [
          { label: 'Todos', value: '' },
          { label: 'Criado', value: 'created' },
          { label: 'Agendada', value: 'scheduled' },
          { label: 'Processado', value: 'processed' },
          { label: 'Entregue', value: 'delivered' },
          { label: 'Aberto', value: 'open' },
          { label: 'Acessado', value: 'click' },
          { label: 'Ignorado', value: 'bounce' },
          { label: 'Recusado', value: 'dropped' },
          { label: 'Spam', value: 'spamreport' }

        ],
        headers: [
          { text: 'Tipo', value: 'type', sortable: false },
          { text: 'Origem', value: 'code', sortable: false },
          { text: 'Token', value: 'token', align: 'center', sortable: false },
          { text: 'Status', value: 'status', align: 'center', sortable: false },
          { text: 'Criado em', value: 'insertedAt', align: 'center', sortable: false },
          { text: 'Atualizado em', value: 'updatedAt', align: 'center', sortable: false },
          { text: 'Reenviar', value: 'resend', sortable: false, align: 'center' }
        ],
        pagination: {
          state: sessionStorage.getItem('InvitePaginationState') || '',
          channel: '',
          page: parseInt(sessionStorage.getItem('InvitePaginationPage') || 1),
          rowsPerPage: pageSize(),
          rowsPerPageItems: [pageSize()]
        },
        series: [],
        rowSelected: {}
      }
    },
    computed: {
      ...mapGetters({
        length: 'invites/length',
        invites: 'invites/list',
        loading: 'invites/listLoading',
        isRender: 'invites/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          sessionStorage.setItem('InvitePaginationState', pagination.state)
          sessionStorage.setItem('InvitePaginationRowsPerPage', pagination.rowsPerPage)
          this.$store.dispatch('invites/getList', pagination)
        },
        deep: true
      },
      deep: true
    },
    beforeMount () {
      this.$store.dispatch('invites/getList', this.pagination)
    },
    methods: {
      nextPage () {
        const pageArgs = this.pagination

        this.paginate({
          ...pageArgs,
          page: pageArgs.page + 1
        })
      },
      paginate (paginate) {
        this.$store.dispatch('invites/getList', paginate)
        this.pagination = paginate
      },
      modalResend (item) {
        this.rowSelected = item
        this.$refs.communicationModal.modalResend(item)
      }
    }
  }
</script>
