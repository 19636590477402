<template>
  <widget-box
    :title="title"
    @screen.once="showElement">
    <v-layout
      row
      wrap>
      <loading
        v-if="loading > 0"
        class="absolute-center" />
      <v-flex
        v-else
        md12
        class="widget-legend px-0 pt-0">
        <div v-if="hasData">
          <apexcharts
            width="100%"
            height="330"
            type="donut"
            :options="donutChartOptions"
            :series="donutChartOptions.series" />
        </div>
        <empty-state
          v-else
          size="75px"
          :icon="$root.icons.layout.empty_chart"
          class="fixed-center">
          No momento não há dados
        </empty-state>
      </v-flex>
    </v-layout>
  </widget-box>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'
  import donutChartMixin from '@/mixins/charts/donut'

  export default {
    mixins: [ widgetsMixin, donutChartMixin ],
    data () {
      return {
        loading: 1
      }
    },
    mounted () {
      if (this.$el.offsetWidth < 404) {
        this.donutChartOptions.plotOptions.pie.offsetY = 20
      }
    },
    methods: {
      processDocument (doc) {
        const reportUnprocessed = doc.clone()

        reportUnprocessed.dropped = reportUnprocessed.dropped + reportUnprocessed.bounce
        delete reportUnprocessed.created
        delete reportUnprocessed.click
        delete reportUnprocessed.deferred
        delete reportUnprocessed.processed
        delete reportUnprocessed.scheduled
        delete reportUnprocessed.bounce

        this.donutChartOptions.labels = Object.keys(reportUnprocessed).map(key => this.$t('invite.state.' + key))
        this.donutChartOptions.series = Object.values(reportUnprocessed)
        this.totalValue = reportUnprocessed.delivered + reportUnprocessed.dropped + reportUnprocessed.open
        this.loading = 0
      },
      processData (ref) {
        ref.onSnapshot(async snapshot => {
          if(snapshot instanceof this.firebase.firestore.QuerySnapshot) {
            const data = snapshot.docs.reduce((acc, item) => {
              const data = item.data()
              for(const key in data) { acc[key] = acc.hasOwnProperty(key) ? acc[key] + data[key] : data[key] }

              return acc
            }, {})

            this.processDocument(data)
          }

          snapshot instanceof this.firebase.firestore.DocumentSnapshot && this.processDocument(snapshot.data())
        })
      },
      showElement () {
        this.loading = 1
        this.$store.dispatch('currentCompany/getInvitesCountsByChannel', { channel: this.channel, attempt: this.attempt }).then(this.processData)
      }
    }
  }
</script>
