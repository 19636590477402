<template>
  <widget-box
    :title="title"
    @screen.once="showElement">
    <v-layout
      row
      wrap>
      <loading
        v-if="loading > 0"
        class="absolute-center" />
      <v-flex
        v-else
        md12
        class="widget-legend widget-radial-bar px-0 pt-0">
        <div v-if="hasData">
          <apexcharts
            width="100%"
            height="430"
            type="radialBar"
            :options="radialBarChartOptions"
            :series="radialBarChartOptions.series" />
        </div>
        <empty-state
          v-else
          size="75px"
          :icon="$root.icons.layout.empty_chart"
          class="fixed-center">
          No momento não há dados
        </empty-state>
      </v-flex>
    </v-layout>
  </widget-box>
</template>

<script>
  import widgetsMixin from '@/mixins/widgets'
  import radialBarChartMixin from '@/mixins/charts/radial-bar'

  export default {
    mixins: [ widgetsMixin, radialBarChartMixin ],
    data () {
      return {
        loading: 1
      }
    },
    methods: {
      processDocument (doc) {
        const reportUnprocessed = doc.clone().takeProperties(['open', 'click'])

        const volumes = Object.values(reportUnprocessed)
        let delivered = volumes[1]
        let percent = (volumes[0] / volumes[1] * 100).toFixed(1)
        percent = isNaN(percent) ? 0 : percent
        let label = delivered < 2 ? 'aberto' : 'abertos'
        this.radialBarChartOptions.labels = [`${delivered} ${label}`]
        this.radialBarChartOptions.series = [percent]
        this.totalValue = percent
        this.loading = 0
      },
      processData (ref) {
        ref.onSnapshot(async snapshot => {
          if(snapshot instanceof this.firebase.firestore.QuerySnapshot) {
            const data = snapshot.docs.reduce((acc, item) => {
              const data = item.data()
              for(const key in data) { acc[key] = acc.hasOwnProperty(key) ? acc[key] + data[key] : data[key] }

              return acc
            }, {})

            this.processDocument(data)
          }

          snapshot instanceof this.firebase.firestore.DocumentSnapshot && this.processDocument(snapshot.data())
        })
      },
      showElement () {
        this.loading = 1
        this.$store.dispatch('currentCompany/getInvitesCountsAccessByChannel', { channel: this.channel, attempt: this.attempt }).then(this.processData)
      }
    }
  }
</script>
